<template>
    <v-card class="pa-2">





        <v-row>
            <v-col cols="12" md="6">
                <v-card outlined>

                    <v-card-title>
                        Agregar pago
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-form @submit.prevent="addPayment" ref="form">
                            <h4>Plan (dias)</h4>
                            <v-radio-group v-model="planDays" row dense :rules="requiredRules">
                                <v-radio label="1" :value="1"></v-radio>
                                <v-radio label="2" :value="2"></v-radio>
                                <v-radio label="3" :value="3"></v-radio>
                                <v-radio label="4" :value="4"></v-radio>
                                <v-radio label="5" :value="5"></v-radio>
                                <v-radio label="6" :value="6"></v-radio>
                                <v-radio label="Libre" :value="0"></v-radio>
                            </v-radio-group>
                            <h4>Periodo (meses)</h4>
                            <v-radio-group v-model="planMonths" row dense :rules="requiredRules">
                                <v-radio label="1" :value="1"></v-radio>
                                <v-radio label="3" :value="3"></v-radio>
                                <v-radio label="6" :value="6"></v-radio>
                                <v-radio label="12" :value="12"></v-radio>

                                <!-- Add other option with custom input  -->
                                <v-radio label="Otro" value="other"></v-radio>
                                <v-text-field filled rounded dense single-line v-if="planMonths == 'other'" hide-details
                                    class="rounded-lg" style="width:100px" :rules="otherPlanMonthsRules"
                                    v-model="otherPlanMonths" type="number" step="1" label="Ingresar Otro"
                                    min-value="0"></v-text-field>


                            </v-radio-group>
                            <h4>Metodo de pago</h4>
                            <v-radio-group v-model="payMethod" row dense :rules="requiredRules">
                                <v-radio label="TARJETA" value="TARJETA"></v-radio>
                                <v-radio label="EFECTIVO" value="EFECTIVO"></v-radio>
                                <v-radio label="TRANSFERENCIA" value="TRANSFERENCIA"
                                    v-if="$store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin'"></v-radio>
                                <v-radio label="LINK" value="LINK"
                                    v-if="$store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin'"></v-radio>


                                <v-radio label="DEBITO AUTOMATICO" value="DEBITO AUTOMATICO"></v-radio>


                            </v-radio-group>



                            <v-radio-group v-model="cardType" v-if="payMethod && payMethod == 'TARJETA'" row
                                :rules="requiredRules" class="mt-0">
                                <v-radio label="VISA" value="VISA"></v-radio>
                                <v-radio label="MASTER" value="MASTER"></v-radio>
                                <v-radio label="OCA" value="OCA"></v-radio>
                                <v-radio label="MAESTRO" value="MAESTRO"></v-radio>
                                <v-radio label="AMEX" value="AMEX"></v-radio>

                            </v-radio-group>

                            <v-radio-group v-model="isCredit" row dense :rules="requiredRules"
                                v-if="payMethod && payMethod == 'TARJETA'" class="mt-0">
                                <v-radio label="CREDITO" value="CREDITO"></v-radio>
                                <v-radio label="DEBITO" value="DEBITO"></v-radio>
                            </v-radio-group>
                            <h4 class="mb-4">Importe total $</h4>
                            <v-text-field rounded filled v-model="payTotal" dense label="Ingresar total"
                                prepend-inner-icon="mdi-cash" class="rounded-lg" :rules="requiredRules"
                                type="number"></v-text-field>


                            <v-divider />
                            <v-card-actions>
                                <!--   <v-btn v-if="!hide" text @click="$emit('payment')">
                Volver
                </v-btn> 
                -->
                                <v-spacer></v-spacer>
                                <v-btn v-if="!hide" type="submit" color="success" :loading="loading">
                                    <v-icon left>mdi-plus</v-icon>
                                    Agregar pago
                                </v-btn>
                            </v-card-actions>

                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">

                <v-card class="fill-height" outlined>
                    <v-card-title>Suscripciones</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <Picker @subscription-selected="subscriptionSelected" />
                    </v-card-text>

                </v-card>


            </v-col>

        </v-row>



        <template v-if="!hide">
            <template v-if="showHistory">

                <v-card outlined class="mt-4">
                    <v-card-title>Historial de pagos</v-card-title>
                    <v-divider />
                    <v-data-table :headers="headers" :items="payments" height="150" disable-filtering disable-pagination
                        disable-sort :loading="loading" mobile-breakpoint="0">
                        <template v-slot:item.createdAt="{ item }">
                            <span>{{ getPaymentDateFormatted(item.createdAt) }}</span>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            <span v-if="item.amount > 0">{{ item.amount }} Dias</span>
                            <span v-else>Pase Libre</span>
                        </template>
                        <template v-slot:item.months="{ item }">
                            <span v-if="item.months > 1">{{ item.months }} Meses</span>
                            <span v-else>{{ item.months }} Mes</span>
                        </template>
                        <template v-slot:item.endOfSubscription="{ item }">
                            <span>{{ getPaymentDateFormatted(item.endOfSubscription) }}</span>
                        </template>
                    </v-data-table>

                </v-card>

            </template>
            <template v-else>
                <v-btn @click="showHistoryToggle" class="mt-4" color="primary">
                    <v-icon left>mdi-magnify</v-icon>
                    Ver Historial de pagos
                </v-btn>

            </template>
        </template>


    </v-card>
</template>

<script>
import { getFirestore, collection, doc, getDocs, addDoc, getDoc, updateDoc, serverTimestamp, Timestamp, setDoc, query, where } from "firebase/firestore";
import moment from "moment";
import { mask } from 'vue-the-mask'
import { set } from 'vue';

import { logAuditEvent } from '@/error/audit.js';

import Picker from '@/components/subscriptions/Picker.vue';

export default {
    components: {
        Picker
    },
    directives: {
        mask
    },
    props: {
        hide: {
            type: Boolean,
            default: false,
        },
        userOBJ: {
            type: Object,
        },
        user: {
            type: String,
        },
        company: {
            type: String,
        },
    },
    data() {
        return {
            showHistory: false,
            headers: [
                { text: "Fecha de cobro", value: "createdAt" },
                { text: "Plan", value: "amount" },
                { text: "Meses", value: "months" },
                { text: "Vence", value: "endOfSubscription" },
                { text: "Monto Pago", value: "total" },
                { text: "ID", value: "id" }
            ],
            payments: [],
            planDays: null,
            planMonths: null,
            loading: false,
            payMethod: null,
            cardType: null,
            isCredit: null,
            cardEnd: null,
            payTotal: null,
            requiredRules: [
                (v) => (v !== null && v !== undefined) || "Este campo es requerido",
            ],
            otherPlanMonthsRules: [
                (v) => (v !== null && v !== undefined) || "Este campo es requerido",
                v => (v && v >= 0) || "Este campo debe ser mayor a 0",
                v => (v && v < 12) || "Este campo debe ser menor a 12",
            ],
            otherPlanMonths: null,
        };
    },
    async created() {
        //await this.fetchPayments();
    },
    methods: {
        async showHistoryToggle() {
            this.showHistory = true;
            await this.fetchPayments();
        },
        async updateEndOfSubscription(userId, endOfSubscription, plan) {
            try {
                const db = getFirestore();
                const userRef = doc(db, `/users/${userId}`);
                await updateDoc(userRef, {
                    endOfSubscription: endOfSubscription,
                    plan: parseInt(plan)
                });
            }
            catch (error) {
                this.$notify({
                    group: "feedback",
                    title: "Error",
                    text: "Ha ocurrido un error al actualizar la fecha de vencimiento." + error.message,
                    type: "error",
                });
            } finally {
                this.loading = false;
            }
        },
        getPaymentDateFormatted(payDate) {
            return moment(payDate.seconds * 1000).format("DD/MM/YYYY");
        },
        sortPaymentsByDate(payments) {
            return payments.sort((a, b) => {
                return a.createdAt.seconds - b.createdAt.seconds;
            });
        },
        async fetchPayments() {

            if (!this.$props.userOBJ) {
                return;
            }

            this.loading = true;

            const db = getFirestore();
            let userQuery = query(collection(db, 'payments'), where('id', '==', this.$props.userOBJ.id));

            const querySnapshot = await getDocs(userQuery);

            let payments = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            this.payments = this.sortPaymentsByDate(payments);

            if (this.payments.length > 0) {
                this.planDays = this.payments[this.payments.length - 1].amount;
            }

            this.loading = false;
        },


        async addPayment() {
            try {
                let validate = this.validateForm();
                if (!validate) {
                    return;
                }

                let confirm = await this.$confirm(
                    "¿Está seguro que desea agregar el pago?",
                    {
                        title: "Confirmar pago",
                        buttonTrueText: "CONFIRMAR",
                        cancelButtonText: "Cancelar",
                        color: "info",
                    }
                );

                if (!confirm) {
                    return;
                }

                this.loading = true;
                const db = getFirestore();

                const paymentData = {
                    id: this.$props.userOBJ.id,
                    amount: this.planDays,
                    createdAt: serverTimestamp(),
                    months: this.planMonths == 'other' ? this.otherPlanMonths : this.planMonths,
                    endOfSubscription: Timestamp.fromDate(this.calculateEndOfSubscription(this.$props.userOBJ)),
                    payMethod: this.payMethod,
                    cardType: this.isCredit + " " + this.cardType,
                    paymentProcessedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email,
                    total: parseFloat(this.payTotal)
                };

                await addDoc(collection(db, `payments`), paymentData);
                await logAuditEvent('create', this.$store.state.Auth.token.claims.user_id, `Payments - addPayment object: ${JSON.stringify(paymentData)}`);

                await this.updateEndOfSubscription(this.$props.userOBJ.id, paymentData.endOfSubscription, paymentData.amount);
                await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Payments - updateEndOfSubscription for user: ${this.$props.userOBJ.id} with endOfSubscription: ${paymentData.endOfSubscription} and plan: ${paymentData.amount}`);

                if (this.showHistory) {
                    await this.fetchPayments();
                }

                this.loading = false;

                this.$notify({
                    group: "feedback",
                    title: "Pago agregado",
                    text: "El pago se ha agregado correctamente.",
                    type: "success",
                });
                this.$emit("payment", paymentData);

                this.resetAllFields();

            } catch (error) {

                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Payments - addPayment error: ${error.message}`);

                this.$notify({
                    group: "feedback",
                    title: "Error",
                    text: "Ha ocurrido un error al agregar el pago." + error.message,
                    type: "error",
                });
                this.loading = false;
            }

        },


        calculateEndOfSubscription(user) {
            // this method calculates the end of subscription based on the last end of subscription, but should also check that if there is no last end of subscription, it should use the current date
            let endOfSubscription = moment();

            if (user && user.endOfSubscription) {

                if (user.endOfSubscription.toDate) {
                    let lastEndOfSubscription = user.endOfSubscription.toDate();
                    endOfSubscription = moment(lastEndOfSubscription);
                } else {
                    let lastEndOfSubscription = new Date(user.endOfSubscription.seconds * 1000);
                    endOfSubscription = moment(lastEndOfSubscription);
                }
            } else {
                endOfSubscription = moment();
            }
            // Add the subscription period to the end of subscription with moment

            let amount = parseInt(this.planMonths == 'other' ? this.otherPlanMonths : this.planMonths);

            endOfSubscription = endOfSubscription.add(amount, "months");

            return endOfSubscription.toDate()
        },
        validateForm() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            return true;
        },
        resetAllFields() {
            this.$refs.form.reset();
        },
        subscriptionSelected({ days, months, price }) {
            if (days == 7) {
                days = 0;
            }
            this.planDays = days;
            this.planMonths = parseInt(months);
            this.payTotal = parseFloat(price);
        },

    },
};
</script>